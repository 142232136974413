<template>
  <div class="mobile-detail">
    <div class="mobile-detail-container" :style="buttons && buttons.length > 0 ? '' : 'margin-bottom:0'">
      <cube-scroll v-if="detail">
        <inputItem title="单据编号" :initialContent="detail.code" :disabled="true"></inputItem>
        <inputItem title="业务分类" :initialContent="(detail.type==3?'其他收款单 - ':'其他付款单 - ')+detail.category" :disabled="true"></inputItem>
        <inputItem title="申请日期" :initialContent="formatDate(detail.time)" :disabled="true"></inputItem>
        <inputItem title="业务归属" :initialContent="detail.owner" :disabled="true"></inputItem>
        <inputItem title="项目名称" :initialContent="detail.project_name" :disabled="true"></inputItem>
        <inputItem title="业务代表" :initialContent="detail.salesman_name" :disabled="true"></inputItem>
        <inputItem title="单位名称" :initialContent="detail.peer_unit" :disabled="true"></inputItem>
        <inputItem title="开户银行" :initialContent="detail.peer_bank" :disabled="true"></inputItem>
        <inputItem title="银行帐号" :initialContent="detail.peer_number" :disabled="true"></inputItem>
        <inputItem title="金额（元）" :initialContent="formatMoney(detail.price)" :disabled="true"></inputItem>
        <inputItem title="申请摘要" :initialContent="detail.memo" :disabled="true"></inputItem>
        <inputItem title="预计核销日期" :initialContent="formatDate(detail.pre_time)" :disabled="true"></inputItem>
        <inputItem title="备注" :initialContent="detail.remark" :disabled="true"></inputItem>
        <inputItem title="申请人" :initialContent="detail.user_name" :disabled="true"></inputItem>
        <div class="mobile-detail-step" v-if="detail && detail.state > 1">
          <Steps :current="flows.step" :status="flows.status" direction="vertical">
            <Step v-for="(item, index) in flows.flowLogs" :key="index" :title="item.userName" :content="formatDate(item.time, 'yyyy-MM-dd hh:mm') + (item.opinion ? ' - ' + item.opinion : '')"></Step>
          </Steps>
        </div>
      </cube-scroll>
    </div>
    <buttonGroup v-if="buttons && buttons.length > 0" :buttons="buttons" @button-click="buttonClick"></buttonGroup>
  </div>
</template>

<script>
import { GetOtherMoney, AuditOtherMoney, CancelOtherMoney } from "@/api";
import { loadDetailAction, auditAction, cancelAction } from "@/utils/action.js";
import { formatDate, formatMoney } from "@/utils/format.js";

export default {
  data: function () {
    return {
      id: "",
      detail: null,
      flows: [],
      buttons: [],
      disabled: false,
    };
  },
  methods: {
    formatDate: formatDate,
    formatMoney: formatMoney,
    buttonClick: function (title) {
      switch (title) {
        case "审核通过":
          this.auditOtherMoney(true, "同意");
          break;
        case "审核拒绝":
          this.auditOtherMoney(false, "");
          break;
        case "撤回":
          this.cancelOtherMoney();
          break;
      }
    },
    auditOtherMoney: function (pass, memo) {
      auditAction(this, AuditOtherMoney, this.id, pass, memo);
    },
    cancelOtherMoney: function () {
      cancelAction(this, CancelOtherMoney, this.id);
    },
    dataRequest: function () {
      loadDetailAction(this, GetOtherMoney, { id: this.id }, (data) => {
        this.detail = data.list[0].model;
        this.flows = data.list[0].flows;
        this.buttons = data.list[0].buttons;
        this.disabled = true;
      });
    },
  },
  mounted() {
    if (this.$route.query.id) {
      this.id = this.$route.query.id;
      this.dataRequest();
    }
  },
};
</script>